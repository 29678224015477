import React from "react";
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import SectionBanner from '../components/SectionBanner'
import ServiceItem from '../components/Services/ServiceItem'
import { IoIosStarOutline } from 'react-icons/io';
import { GiGearHammer } from 'react-icons/gi';
import { HiOutlinePhone } from 'react-icons/hi';
import ContactForm from '../components/Contact/ContactForm'
import SEO from '../components/SEO'



const About = ({ data }) => {
  const components = data.contentfulPage.components
  const bannerData = components[0]
  const aboutData = components[1]
  const secondaryBanner = components[2]

  return (
    <Layout>
      <SEO title="VM Construction | About Our Company" description="VM Construction has been providing home solutions for over 20 years around Northamptonshire. With our expertise in all building projects, we provide upscale kitchen, bathroom, and whole-home renovations, conversions, fittings, maintenance and more." />
      <SectionBanner data={bannerData} />
      <ServiceItem data={aboutData} />
      <SectionBanner data={secondaryBanner} />
      <div className="bg-gray-darkest h-auto flex flex-wrap items-center space-between justify-center py-10">
        <div className="flex flex-col px-4 items-center text-center text-gray-light">
          <IoIosStarOutline data-sal="slide-up"
            data-sal-delay="300"
            data-sal-easing="ease" className="text-7xl" />
          <div className="font- py-2">Honest & Helpful</div>
        </div>
        <div className="flex flex-col px-4 items-center text-center text-gray-light">
          <GiGearHammer data-sal="slide-up"
            data-sal-delay="300"
            data-sal-easing="ease" className="text-7xl" />
          <div className="font- py-2">Commitment to Quality</div>
        </div>
        <div className="flex flex-col px-4 items-center text-center text-gray-light">
          <HiOutlinePhone data-sal="slide-up"
            data-sal-delay="300"
            data-sal-easing="ease" className="text-7xl" />
          <div className="font- py-2">Excellent Communication </div>
        </div>
      </div>
      <ContactForm title="Request Your Consultation" subtitle="" />
    </Layout>
  );
}

export const query = graphql`
query AboutPage {
    contentfulPage(contentful_id: {eq: "4HPV3yNChLQ8NlSMHpL5ao"}) {
      id
      title
      description
      components {
        ... on ContentfulContentModules {
          title
          subtitle
          componentImage {
            gatsbyImageData(quality: 90, width: 800)
          }
          childContentfulContentModulesParagraphTextNode {
            childMarkdownRemark {
              html
            }
          }
        }
        ... on ContentfulBanner {
          title
          subtitle
          bannerImage {
            gatsbyImageData(quality: 90, width: 1200)
          }
        }
      }
    }
  }
  `

export default About